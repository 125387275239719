import httpUtil from "@/utils/httpUtil";

/**添加电子样册 */
export const albumAddAlbum =  params => httpUtil.post("/api/crmPc/album/addAlbum", params);

/**修改电子样册 */
export const albumUpdateAlbum =  params => httpUtil.post("/api/crmPc/album/updateAlbum", params);

/**删除电子样册 */
export const albumDeleteAlbum =  params => httpUtil.post("/api/crmPc/album/deleteAlbum", params);

/**电子样册列表 */
export const albumSelectAlbum =  params => httpUtil.post("/api/crmPc/album/selectAlbum", params);

/**添加电子样册分类 */
export const photoAlbumSortAdd =  params => httpUtil.post("/api/crmPc/photoAlbumSort/add", params);

/**修改电子样册分类 */
export const photoAlbumSortUpdate =  params => httpUtil.post("/api/crmPc/photoAlbumSort/update", params);

/**删除电子样册分类 */
export const photoAlbumSortDeleteById =  params => httpUtil.post("/api/crmPc/photoAlbumSort/deleteById", params);

/**电子样册分类列表 */
export const photoAlbumSortQuery =  params => httpUtil.post("/api/crmPc/photoAlbumSort/query", params);